import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";
import { ChevronRightIcon } from "@heroicons/react/outline";
import { Link } from "gatsby";
import Client from "./client";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
    [MARKS.ITALIC]: (text) => <i>{text}</i>,
    [MARKS.UNDERLINE]: (text) => <u>{text}</u>,
    [MARKS.CODE]: (text) => <code>{text}</code>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => {
      if (
        node &&
        node.content[0] &&
        node.content[0].value &&
        node.content[0].value.length !== 0
      ) {
        return <p className="pb-4 leading-relaxed">{children}</p>;
      }
    },
    [INLINES.HYPERLINK]: (node, children) => (
      <a
        className="text-black underline underline-offset-2 decoration-black dark:decoration-[#94a3b8] decoration-2 dark:text-slate-400"
        href={node.data.uri}
      >
        {children}
      </a>
    ),
    [BLOCKS.QUOTE]: (node, children) => (
      <p className="pb-4 leading-relaxed text-gray-900">{children}</p>
    ),

    [BLOCKS.HEADING_1]: (node, children) => {
      let string = String(children).replace(/\s+/g, "_");
      return (
        <h1
          className="text-2xl font-extrabold tracking-tight text-gray-900 dark:text-slate-400 pb-4 pt-8"
          id={string}
        >
          {children}
        </h1>
      );
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      let string = String(children).replace(/\s+/g, "_");
      return (
        <h2
          className="text-xl font-bold tracking-tight text-gray-900 dark:text-slate-400 py-4"
          id={string}
        >
          {children}
        </h2>
      );
    },
    [BLOCKS.HEADING_3]: (node, children) => (
      <h3 className="text-lg font-bold tracking-tight text-gray-900 dark:text-slate-400 py-4">
        {children}
      </h3>
    ),
    [BLOCKS.HEADING_4]: (node, children) => <h4>{children}</h4>,
    [BLOCKS.HEADING_5]: (node, children) => <h5>{children}</h5>,
    [BLOCKS.HEADING_6]: (node, children) => <h6>{children}</h6>,
    [BLOCKS.UL_LIST]: (node, children) => (
      <ul className="list-disc space-y-2 pl-6 ">{children}</ul>
    ),
    [BLOCKS.LIST_ITEM]: (node, children) => (
      <li className="text-interaction">
        <div className="text-black dark:text-slate-400 ">
          {node.content[0].content[0].value}
        </div>
      </li>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <ul className="list-decimal space-y-2 px-8">{children}</ul>
    ),
  },
};

export default function Card(props) {
  return (
    <div className="flex flex-col break-inside-avoid">
      <div className="flex items-center mb-2 ml-4 sm:-ml-[1.6rem] print:-ml-[1.6rem] bg-gray-50 dark:bg-gray-900  print:bg-white">
        <div
          className={
            "flex items-center bg-[" + props.color + "] rounded-full w-4 h-4"
          }
        ></div>

        <div className="text-sm font-semibold flex items-center p-1 pl-3  text-gray-600 dark:text-slate-400 w-fit rounded-md">
          {props && props.subtitle ? props.subtitle : ""}
        </div>
      </div>
      <div className="group mb-6 bg-white dark:bg-gray-800 print:bg-gray-50 print:rounded-xl sm:rounded-xl md:max-w-screen-xl flex flex-col p-4 sm:p-6">
        <Client
          image={props.logo}
          title={props.role}
          subtitle={props.title}
          variant={"large"}
        />

        <div className="mt-4 flex-1 flex flex-col">
          {props.content ? (
            <div className="text-sm">
              {typeof props.content == "object"
                ? renderRichText(props.content, options)
                : props.content}
            </div>
          ) : (
            ""
          )}
          {props.consultant ? (
            <div>
              <div className="flex pt-6 pb-4 w-fit flex-col">
                <h3 className="text-md font-medium dark:text-slate-200">
                  Selected clients
                </h3>
                {/* <div className="bg-gradient-to-br from-[#49b6ff] to-interaction h-0.5 rounded-full"></div> */}
              </div>

              <div className="grid md:grid-cols-1 gap-3">
                {props.clients &&
                  props.clients.map((client) => (
                    <div
                      key={client.id + 100}
                      className="flex-1 flex align-middle"
                    >
                      <Client
                        image={client.logo}
                        title={client.name}
                        subtitle={client.dateFrom + " - " + client.dateTo}
                        variant={"medium"}
                      />

                      <p className="text-sm">
                        {client && client.role ? client.role : ""}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}

          {props.subCards ? (
            <div className="print:hidden">
              <div className="flex pt-6 pb-4 w-fit flex-col">
                <h3 className="text-md font-medium dark:text-slate-200">
                  Selected projects
                </h3>
                {/* <div className="bg-gradient-to-br from-[#49b6ff] to-interaction h-0.5 rounded-full"></div> */}
              </div>

              <div className="grid md:grid-cols-2 gap-4">
                {props.subCards &&
                  props.subCards.map((project) => (
                    <div key={project.id + 100} className="flex">
                      <div className="items-center">
                        <div
                          key={project.id}
                          className="flex flex-nowrap shadow-sm shadow-gray-200 dark:shadow-interaction border-gray-200 hover:shadow-md bg-white dark:bg-gray-800 rounded-xl"
                        >
                          <Link
                            to={"/shared/portfolio/" + project.slug}
                            className="flex flex-row"
                          >
                            <div className="">
                              <GatsbyImage
                                image={getImage(project.smallCover)}
                                alt="client logo"
                                className="w-32 rounded-l-xl dark:rounded-tr-xl"
                              />
                            </div>
                            <div className="flex flex-col align-middle h-full p-4 justify-between">
                              <div className=" w-full">
                                <Client
                                  image={project.client.logo}
                                  title={project.client.name}
                                  variant={"medium"}
                                />

                                <p className="text-sm mt-2 text-gray-500 dark:text-slate-400 w-full line-clamp-4 italic">
                                  {project.teaser}
                                </p>
                              </div>
                              <div className="flex items-center justify-end text-sm mt-2">
                                <span className="font-semibold text-gray-900 dark:text-white">
                                  Read case
                                </span>
                                <ChevronRightIcon className="h-6 w-6 text-interaction" />
                              </div>
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}
