import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout";
import Card from "../../components/card";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function Cv({ data, location }) {
  const [button, setButton] = useState(false);
  function openModal() {
    setButton(true);
    setTimeout(() => {
      setButton(false);
    }, 3000);
  }
  const path =
    typeof location !== "undefined" && location.pathname
      ? location.pathname
      : "";
  return (
    <Layout location={"/shared/cv/"}>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content="Ole Lillebos CV" />
        <title>CV</title>
        <html lang="en" amp />
        <link rel="canonical" href="https://www.olelillebo.com/cv/" />
      </Helmet>
      <div className="bg-gray-50 dark:bg-gray-900 dark:text-slate-400 print:bg-white pt-16 -mt-16">
        <div className="max-w-screen-md mx-auto print:px-8 sm:px-4 pb-6 ">
          {/*           <div className="flex items-center p-2">
            <div className="flex items-center justify-center -ml-[1.6rem] bg-[#00bbf9] text-white text-2xl font-extrabold rounded-full w-10 h-10">
              💼
            </div>
            <h2 className="text-2xl font-extrabold w-fit pl-2">
              Personal information
            </h2>
          </div> */}
          {/*           <div className="group mb-6 flex flex-row py-6 justify-center  bg-gradient-to-br from-[#49b6ff] to-[#ff499e]  rounded-xl">
            <div className="flex items-center justify-center p-8">
              <StaticImage
                src="../images/ole2.png"
                alt="Profile picture"
                layout="fixed"
                width={150}
                height={150}
                className="rounded-full border-4 border-white"
              />
            </div>
            <div className="flex flex-1">
              <p className="text-lg flex items-center text-white">
                11+ years experience building UX Impact and solving the right
                problems at market-leading clients
              </p>
            </div>
          </div> */}
          <div className="flex flex-col py-6 justify-center items-center ">
            <div
              onClick={() => openModal()}
              className={classNames(
                button
                  ? "!p-0 border-4 border-dashed border-gray-50 dark:border-gray-900 animate-spin2"
                  : "",
                "group mb-2 flex flex-row p-1 justify-center w-fit bg-gradient-to-br from-[#49b6ff] to-interaction rounded-full"
              )}
            >
              <div className=" flex flex-row justify-center  rounded-full">
                <div className="flex items-center justify-center">
                  <StaticImage
                    src="../images/ole2.png"
                    alt="Profile picture"
                    layout="fixed"
                    width={150}
                    height={150}
                    className={classNames(
                      button ? "animate-spinReverse" : "",
                      "rounded-full border-4 border-gray-50 dark:border-gray-900 "
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="flex items-center text-4xl mb-2 font-extrabold font-Caveat text-gray-900 justify-center">
              Ole Lillebo
            </div>
            <p className="text-lg italic font-light w-64 flex items-center text-center">
              11+ years experience building UX Impact and solving the right
              problems at Industry-leading clients
            </p>
          </div>
          <div className="flex items-center sm:pl-2 mb-1 justify-center sm:justify-start flex-col sm:flex-row print:flex-row print:justify-start print:pl-2">
            <div className="flex items-center justify-center sm:-ml-[1.6rem] print:-ml-[1.6rem] bg-[#00bbf9] text-white  text-2xl font-extrabold rounded-full w-10 h-10">
              💼
            </div>
            <h2 className="text-2xl font-bold w-fit py-2 print:py-0 print:pl-2 sm:py-0 sm:pl-2 dark:text-slate-200">
              Work experience
            </h2>
          </div>
          <div className="sm:border-l-4 print:border-l-4 border-[#00bbf9] border-opacity-30 border-dotted sm:pl-4 print:pl-4 pb-8">
            {data.employers.nodes.map((employer) => (
              <Card
                key={employer.id}
                role={employer.role}
                title={employer.name}
                logo={employer.logo}
                consultant={employer.consultant}
                clients={employer.clients}
                color={"#00bbf9"}
                subtitle={
                  employer.fromDate +
                  " - " +
                  (employer.toDate ? employer.toDate : "")
                }
                content={employer.description}
                subCards={
                  employer.project
                    ? employer.project.sort((a, b) => {
                        return new Date(a.period) > new Date(b.period) ? -1 : 1;
                      })
                    : ""
                }
              />
            ))}
          </div>

          <div className="flex items-center sm:pl-2 mb-1 justify-center sm:justify-start flex-col sm:flex-row print:flex-row print:justify-start print:pl-2">
            <div className="flex items-center justify-center sm:-ml-[1.6rem] print:-ml-[1.6rem] bg-[#00f5d4] text-white text-2xl font-extrabold rounded-full w-10 h-10">
              📚
            </div>
            <h2 className="text-2xl font-bold w-fit py-2 print:py-0 print:pl-2 sm:py-0 sm:pl-2 dark:text-slate-200">
              Education
            </h2>
          </div>
          <div className="sm:border-l-4 print:border-l-4 border-[#00f5d4] border-opacity-30 border-dotted sm:pl-4 print:pl-4 pb-8">
            {data.education.nodes.map((university) => (
              <Card
                key={university.id}
                title={university.name}
                role={university.role}
                logo={university.logo}
                subtitle={university.fromDate + " - " + university.toDate}
                content={university.description}
                color={"#00f5d4"}
              />
            ))}
          </div>
          <div className="flex items-center sm:pl-2 mb-1 justify-center sm:justify-start flex-col sm:flex-row print:flex-row print:justify-start print:pl-2">
            <div className="flex items-center justify-center sm:-ml-[1.6rem] print:-ml-[1.6rem] bg-[#f15bb5] text-white text-2xl font-extrabold rounded-full w-10 h-10">
              🏅
            </div>
            <h2 className="text-2xl font-bold w-fit py-2 print:py-0 print:pl-2 sm:py-0 sm:pl-2 dark:text-slate-200">
              Training/courses
            </h2>
          </div>
          <div className="sm:border-l-4 print:border-l-4 border-[#f15bb5] border-opacity-30 border-dotted sm:pl-4 print:pl-4 pb-8">
            {data.training.nodes.map((training) => (
              <Card
                key={training.id}
                title={training.name}
                logo={training.logo}
                role={training.role}
                subtitle={training.fromDate}
                content={training.description}
                color={"#f15bb5"}
              />
            ))}
          </div>
          <div className=" items-center mb-2 -ml-[0.4rem] bg-gray-50 dark:bg-gray-900 print:bg-white hidden sm:flex">
            <div className="flex items-center bg-gray-200 dark:bg-gray-800 rounded-full w-4 h-4"></div>
            <div className="text-sm font-semibold flex items-center p-1 pl-3  text-transparent w-fit rounded-md h4">
              -
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    employers: allContentfulEmployer(sort: { fields: fromDate, order: DESC }) {
      nodes {
        id
        toDate(formatString: "MMM YYYY")
        role
        industry
        name
        location
        consultant
        fromDate(formatString: "MMM YYYY")
        project {
          teaser
          id
          role
          slug
          shortTeaser
          smallCover {
            gatsbyImageData(height: 800)
          }
          period(formatString: "MMM YYYY")
          periodTo(formatString: "MMM YYYY")
          client {
            id
            name
            dateFrom(formatString: "MMM YYYY")
            dateTo(formatString: "MMM YYYY")
            description {
              raw
            }
            logo {
              gatsbyImageData(width: 400)
            }
            project {
              title
            }
          }
        }
        description {
          raw
        }
        logo {
          gatsbyImageData(width: 400)
        }
        clients {
          id
          name
          dateFrom(formatString: "MMM YYYY")
          dateTo(formatString: "MMM YYYY")
          description {
            raw
          }
          logo {
            gatsbyImageData(width: 400)
          }
          project {
            title
          }
        }
      }
    }
    education: allContentfulEducation(sort: { fields: toDate, order: DESC }) {
      nodes {
        id
        toDate(formatString: "MMM YYYY")
        role
        name
        location
        description {
          raw
        }
        fromDate(formatString: "MMM YYYY")

        logo {
          gatsbyImageData(width: 400)
        }
      }
    }
    training: allContentfulTraining(sort: { fields: fromDate, order: DESC }) {
      nodes {
        id
        role
        name
        location
        description {
          raw
        }
        fromDate(formatString: "MMM YYYY")
        logo {
          gatsbyImageData(width: 400)
        }
      }
    }
  }
`;

export default Cv;
